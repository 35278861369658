import React from "react"
import AniLink from "gatsby-plugin-transition-link/AniLink"

import styled from "styled-components"

const Charity = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2.5rem;
  background-color: #f9f8ee;
  h1 {
    font-size: 1.9rem;
    margin-bottom: 0.5rem;
    text-align: center;
  }

  .charites {
    display: flex;
    align-items: flex-end;
    .charity {
      padding: 0.5rem;
      margin: 1rem 1rem;
      img {
        padding-bottom: 0.7rem;
        margin: 0 auto;
        width: 80%;
        border-bottom: 2px solid #dca341;
        @media (max-width: 960px) {
          width: 60%;
        }
        @media (max-width: 360px) {
          width: 100%;
        }
      }
      p {
        padding: 0.7rem;
        text-align: center;
        font-family: "GillSans", "sans";
        height: 155px;
        overflow: hidden;
      }
    }
    @media (max-width: 960px) {
      flex-direction: column;
      p {
        font-size: 1rem;
        line-height: 1.5;
      }
    }
  }
`

const Button = styled(AniLink)`
  display: flex;
  justify-content: center;
  align-content: center;
  padding: 1.3rem;
  margin-top: 0.5rem;
  background-color: #000;
  color: #000;
  cursor: pointer;
  text-transform: uppercase;
  text-align: center;
  text-decoration: none;
  position: relative;
  margin: 0 auto;
  font-weight: 600;
  box-shadow: -1px 6px 12px -2px rgba(0, 0, 0, 0.75);
  background: rgb(247, 209, 134);
  background: radial-gradient(
    circle,
    rgba(247, 209, 134, 1) 0%,
    rgba(248, 211, 138, 1) 31%,
    rgba(224, 169, 74, 1) 78%
  );
`

const CharityLogos = props => {
  return (
    <>
      <Charity>
        <h1>{props.data.charity_partner_title}</h1>
        <div className="charites">
          <div className="charity">
            <img
              src={props.data.charity_section.charity_one_logo.source_url}
              alt={props.data.charity_section.charity_one_logo.title}
            />
            <p
              dangerouslySetInnerHTML={{
                __html: props.data.charity_section.charity_one_text,
              }}
            />
          </div>
          <div className="charity">
            <img
              src={props.data.charity_section.charity_two_logo.source_url}
              alt={props.data.charity_section.charity_two_logo.title}
            />
            <p
              dangerouslySetInnerHTML={{
                __html: props.data.charity_section.charity_two_text,
              }}
            />
          </div>
          <div className="charity">
            <img
              src={props.data.charity_section.charity_three_logo.source_url}
              alt={props.data.charity_section.charity_three_logo.title}
            />
            <p
              dangerouslySetInnerHTML={{
                __html: props.data.charity_section.charity_three_text_new,
              }}
            />
          </div>
        </div>
        <Button
          // cover
          // direction="right"
          // duration={1.5}
          // bg="#e0a94a"
          to="/charities"
          aria-label="Charity"
        >
          More Partners
        </Button>
      </Charity>
    </>
  )
}

export default CharityLogos
